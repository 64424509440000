import closeIcon from "assets/images/11.svg";
import caretImg from "assets/images/18.svg";
import cx from "classnames";
import { Checkbox } from "components/utils";
import { DownshiftState, useCombobox, UseComboboxStateChangeOptions } from "downshift";
import React, { ReactNode, useRef, useState } from "react";
import { useTrimmedArray } from "../../../hooks/useTrimmedArray";
import { assertIsDefined } from "../../../utilities/assertIsDefined";
import { noop } from "../../../utilities/noop";
import { PopoverMenu } from "../../utils/Popover";
import styles from "./AdvancedSelect.module.css";

interface Props<T, U extends keyof T, M> {
  placeholder?: string;
  inputPlaceholder?: string;
  items: T[];
  onChange: (item: T | null, value: string | null) => void;
  width?: number | string;
  minWidth?: number;
  itemsWidth?: number | string;
  itemToDisplay?: (item: T, isSelected: boolean) => React.ReactNode;
  itemToDisplaySelected?: (item: T | null) => React.ReactNode;
  selectBy?: U;
  searchEnabled?: boolean;
  disabled?: boolean;
  label?: string;
  theme?: "light" | "dark";
  enableClear?: boolean;
  multiple?: M;
  showAll?: boolean;
  overrides?: {
    toggleButton?: { style?: React.CSSProperties };
    wrapper?: { className?: string };
    list?: { className?: string };
    label?: { className?: string };
  };
  badgeToDisplay?: (item: T) => ReactNode;
}
type PropsSingle<T, U extends keyof T, M> = Props<T, U, M> & {
  selectedItem: T[U] | null;
  selectedItems?: undefined;
};
type PropsMultiple<T, U extends keyof T, M> = Props<T, U, M> & {
  selectedItems: T[U][];
  selectedItem?: undefined;
};

const createStateReducer = (multiple: boolean) =>
  function stateReducer(
    state: DownshiftState<string>,
    actionAndChanges: UseComboboxStateChangeOptions<string>,
  ) {
    const { type, changes } = actionAndChanges;
    // this prevents the menu from being closed when the user selects an item with 'Enter' or mouse
    switch (type) {
      // avoid clear after ESC click
      case useCombobox.stateChangeTypes.InputKeyDownEscape:
        return {
          ...changes,
          selectedItem: state.selectedItem,
          inputValue: state.inputValue || "",
        };
      // clear input value on change
      case useCombobox.stateChangeTypes.InputKeyDownEnter:
      case useCombobox.stateChangeTypes.ItemClick:
        return {
          ...changes, // default Downshift new state changes on item selection.
          inputValue: multiple ? state.inputValue || "" : "",
          isOpen: multiple ? state.isOpen : changes.isOpen,
        };
      case useCombobox.stateChangeTypes.ToggleButtonClick:
        return {
          ...changes,
          inputValue: "",
        };
      case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
        return {
          ...changes,
          inputValue: multiple ? state.inputValue || "" : "",
          isOpen: multiple ? state.isOpen : changes.isOpen,
        };

      default:
        return changes; // otherwise business as usual.
    }
  };

export function AdvancedSelect<
  T extends {
    id: number | string;
    name: string;
    content?: React.ReactNode;
    filters: { [key: string]: string };
  },
  U extends keyof T,
  M extends boolean = false
>({
  placeholder = "Choose an element",
  inputPlaceholder = "Find...",
  onChange,
  multiple = false,
  items,
  label,
  disabled,
  width: fixedWidth,
  itemsWidth: listWidth,
  minWidth = 0,
  selectedItem: outerSelectedItem,
  selectedItems,
  selectBy,
  itemToDisplay,
  itemToDisplaySelected,
  enableClear = true,
  searchEnabled = true,
  showAll = false,
  theme = "light",
  badgeToDisplay,
  overrides = {},
}: M extends true ? PropsMultiple<T, U, M> : PropsSingle<T, U, M>) {
  const [inputItems, setInputItems] = useState(
    items.map(item => {
      return {
        name: item.name,
        content: item.content ? item.content : null,
      };
    }),
  );
  const {
    isOpen,
    inputValue,
    setInputValue,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getLabelProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem,
  } = useCombobox({
    stateReducer: createStateReducer(multiple),
    selectedItem: items.find(item => item[selectBy || "id"] === outerSelectedItem)?.name || null,
    initialInputValue: "",
    items: inputItems.map(inputItem => inputItem.name),
    onInputValueChange: ({ inputValue }) => {
      // Go after all fields passed in the 'filters' object and check if any of them starts with given inputValue
      setInputItems(
        items
          .filter(item => {
            const values = Object.values(item.filters);
            return values.some(value => value.toLowerCase().startsWith(inputValue!.toLowerCase()));
          })
          .map(item => {
            return {
              name: item.name,
              content: item.content ? item.content : null,
            };
          }),
      );
    },
    onSelectedItemChange: downshift => {
      const item = items.find(_item => _item.name === downshift.selectedItem);
      onChange(item || null, downshift.selectedItem || null);
    },
  });
  const ref = useRef<HTMLDivElement | null>(null);

  function findItemByName(name: string) {
    const item = items.find(item => item.name === name);
    assertIsDefined(item, "item in select");
    return item;
  }

  function handleItemToDisplay(item: string) {
    const fullItem = findItemByName(item);
    assertIsDefined(fullItem, "fullItem in select");
    assertIsDefined(itemToDisplay, "itemToDisplay in select");
    if (multiple && selectedItems) {
      return itemToDisplay(
        fullItem,
        selectedItems.some(selectedItem => selectedItem === fullItem[selectBy || "id"]),
      );
    } else {
      return itemToDisplay(fullItem, selectedItem === fullItem[selectBy || "id"]);
    }
  }
  return (
    <div
      style={{ width: fixedWidth }}
      {...getComboboxProps({
        className: cx(styles[theme], overrides.wrapper?.className),
        ref: ref,
      })}
    >
      {/* Hacky workaround to avoid console warnings */}
      <div className={styles.fakeInputContainer}>
        <input
          {...getInputProps({
            className: styles.fakeInput,
            autoFocus: false,
            onBlurCapture: e => {
              e.stopPropagation();
              e.preventDefault();
            },
          })}
        />
      </div>
      {/* Hacky workaround to avoid console warnings */}
      <div ref={getMenuProps().ref} />

      <div className="position-relative">
        {Boolean(label) && (
          <label {...getLabelProps({ className: cx(styles.label, overrides.label?.className) })}>
            {label}
          </label>
        )}
        <PopoverMenu
          isOpen={isOpen}
          placement="bottom-start"
          overflowContainer
          triggerOffset={0}
          target={btnProps => (
            <div className="d-flex align-items-center justify-content-center">
              <button
                type="button"
                {...getToggleButtonProps({
                  ...btnProps,
                  style: { minWidth, ...overrides.toggleButton?.style },
                })}
                aria-label={"toggle menu"}
                className={styles.btn}
              >
                <div className="overflow-hidden">
                  {itemToDisplaySelected ? (
                    itemToDisplaySelected(selectedItem ? findItemByName(selectedItem) : null)
                  ) : (
                    <div>
                      {selectedItem || <span className={styles.placeholder}>{placeholder}</span>}
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-end">
                  {selectedItem && enableClear && (
                    <>
                      <img
                        src={closeIcon}
                        alt="wyczyść"
                        onClick={e => {
                          e.stopPropagation();
                          onChange(null, null);
                        }}
                        className="cursor-pointer ml-1"
                      />
                      <div className={styles.separator} />
                    </>
                  )}
                  <img src={caretImg} alt="" className={styles.caret} />
                </div>
              </button>
            </div>
          )}
        >
          <ul
            {...getMenuProps({
              className: cx(
                styles.list,
                {
                  [styles.unsearchableList]: searchEnabled === false,
                  [styles.open]: isOpen && !disabled,
                },
                overrides.list?.className,
              ),
              style: {
                width: listWidth ? (listWidth < minWidth ? minWidth : listWidth) : undefined,
              },
            })}
          >
            {isOpen && !disabled && (
              <>
                {searchEnabled && (
                  <li className={styles.searchLi}>
                    <input
                      value={inputValue}
                      className={styles.input}
                      onChange={e => setInputValue(e.target.value)}
                      placeholder={inputPlaceholder}
                      autoFocus
                    />
                  </li>
                )}
                {inputItems.map((item, index) => (
                  <li
                    style={highlightedIndex === index ? { backgroundColor: "#efeeec" } : {}}
                    key={`${item.name}-${index}`}
                    {...getItemProps({ item: item.name, index })}
                  >
                    {itemToDisplay ? (
                      handleItemToDisplay(item.name)
                    ) : multiple && selectedItems ? (
                      <div className="d-flex">
                        <Checkbox
                          className="pe-none"
                          checked={selectedItems.some(
                            selectedItem =>
                              selectedItem === findItemByName(item.name)[selectBy || "id"],
                          )}
                          onChange={noop}
                          label=""
                          name=""
                        />
                        {item.name}
                      </div>
                    ) : (
                      <div className="d-flex align-items-center gap-2">
                        <div>{item.name}</div>
                        {item.content}
                      </div>
                    )}
                  </li>
                ))}
              </>
            )}
          </ul>
        </PopoverMenu>
      </div>
      {multiple && selectedItems ? (
        <Badges
          items={items}
          selectedItems={selectedItems}
          selectBy={selectBy}
          selectItem={selectItem}
          showAll={showAll}
          badgeToDisplay={badgeToDisplay}
        />
      ) : null}
    </div>
  );
}

const Badges = <T extends { id: number | string; name: string }, U extends keyof T>({
  selectedItems,
  items,
  selectBy,
  selectItem,
  showAll,
  badgeToDisplay = (item: T) => item.name,
}: {
  items: T[];
  selectedItems: T[U][];
  selectBy?: U;
  showAll?: boolean;
  badgeToDisplay?: (item: T) => ReactNode;
  selectItem: (item: string) => void;
}) => {
  const formattedItems = selectedItems.map(
    id => items.find(item => item[selectBy || "id"] === id)!,
  );
  const trim = 3;
  const [trimmedItems, { toggle, areTrimmed, isTrimmable }] = useTrimmedArray(formattedItems, trim);
  return (
    <div className="mt-2">
      {(showAll ? formattedItems : trimmedItems).map(item => (
        <div key={item.id} className={styles.badge}>
          {badgeToDisplay(item)}{" "}
          <button type="button" onClick={() => selectItem(item.name)}>
            <img src={closeIcon} alt="remove" />
          </button>
        </div>
      ))}
      {areTrimmed && isTrimmable && !showAll && (
        <button type="button" className={cx(styles.trimCounter)} onClick={toggle}>
          <span className="text-color-grey">+{formattedItems.length - trimmedItems.length}</span>
        </button>
      )}
      {!areTrimmed && isTrimmable && !showAll && (
        <button type="button" onClick={toggle}>
          <span className="fs-12 text-color-grey fw-500">Pokaż mniej</span>
        </button>
      )}
    </div>
  );
};
