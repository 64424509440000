import { Pagination } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { manufacturingNewKeys } from "./keys";
import {
  ManufacturingSchema,
  ManufacturingStage,
  PostManufacturingSchema,
  PostManufacturingStage,
} from "./models";

export const getManufacturingSchemas = (
  search: string = "",
): ApiFetcher<Pagination<ManufacturingSchema>> => ({
  key: manufacturingNewKeys.manufacturingSchemas(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/schemas/entries" + search,
    }),
});

export const postManufacturingSchema = (data: PostManufacturingSchema) =>
  queryFetch<ManufacturingSchema>({
    method: "POST",
    url: "/manufacturing/schemas/entries",
    data,
  });

export const getManufacturingSchema = (id: string): ApiFetcher<ManufacturingSchema> => ({
  key: manufacturingNewKeys.manufacturingSchema(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/schemas/entries/" + id,
    }),
});

export const getManufacturingStages = (
  search: string = "",
): ApiFetcher<Pagination<ManufacturingStage>> => ({
  key: manufacturingNewKeys.manufacturingStages(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/schemas/stages" + search,
    }),
});

export const getManufacturingStage = (id: string): ApiFetcher<ManufacturingStage> => ({
  key: manufacturingNewKeys.manufacturingStage(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/schemas/stages/" + id,
    }),
});

export const postManufacturingStage = (data: PostManufacturingStage) =>
  queryFetch<ManufacturingSchema>({
    method: "POST",
    url: "/manufacturing/schemas/stages",
    data,
  });
