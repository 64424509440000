import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import person4 from "assets/images/person4.svg";
import { Dashboard } from "routes/humanResources/Dashboard";

export const drivers: ModuleLink = {
  url: "drivers",
  label: "Kierowcy",
  icon: person4,
  subSection: <></>,
  routing: Dashboard,
};
