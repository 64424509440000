import {
  EmployeeWorkingDay as EmployeeWorkingDayModel,
  WorkingDay,
} from "api/new-production-plans/models";
import cx from "classnames";
import localStyles from "./EmployeeWorkingDay.module.css";
import { EmptyManufacturingItem } from "./subcomponents/manufacturingItem/EmptyManufacturingItem";
import { ManufacturingItem } from "./subcomponents/manufacturingItem/ManufacturingItem";
import { Slots } from "./subcomponents/slots/Slots";

interface Props {
  days: WorkingDay[];
  employeeWorkingDay: EmployeeWorkingDayModel;
}

export const EmployeeWorkingDay = ({ days, employeeWorkingDay }: Props) => {
  return (
    <div
      className={cx(localStyles.dayCard, {
        [localStyles.dayCardOff]: !employeeWorkingDay.isAvailable,
        [localStyles.dayCardHoliday]: days.find(day => day.date === employeeWorkingDay.date)!
          .isHoliday,
        [localStyles.dayCardSaturday]:
          days.find(day => day.date === employeeWorkingDay.date)!.dayOfTheWeek === "SATURDAY",
        [localStyles.dayCardSunday]:
          days.find(day => day.date === employeeWorkingDay.date)!.dayOfTheWeek === "SUNDAY",
      })}
    >
      <div className={localStyles.dayCardInner}>
        <Slots employeeWorkingDay={employeeWorkingDay} />
        <div
          className={cx(localStyles.manufacturingItems, {
            "mt-1": employeeWorkingDay.manufacturingWorkingUnits.length > 0,
          })}
        >
          {employeeWorkingDay.manufacturingWorkingUnits.length > 0 ? (
            <>
              {employeeWorkingDay.manufacturingWorkingUnits.map(manufacturingItem => (
                <ManufacturingItem manufacturingItem={manufacturingItem} />
              ))}
            </>
          ) : (
            <EmptyManufacturingItem />
          )}
        </div>
      </div>
    </div>
  );
};
