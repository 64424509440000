import { Pagination, UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { PartialOf } from "typeUtilities";
import { productionPlansKeys } from "./keys";
import {
  AddOrRemoveEmployeesFromProductionPlan,
  AddOrRemoveWorkingDaysInProductionPlan,
  ManufacturingSchema,
  PostProductionPlan,
  ProductionPlan,
  ProductionPlanListItem,
  SchemaStage,
  ShelfItem,
  ShelfManufacturingItem,
} from "./models";

export const getProductionPlans = (
  search: string = "",
): ApiFetcher<Pagination<ProductionPlanListItem>> => ({
  key: productionPlansKeys.productionPlans(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/stage-production-plans/entries" + search,
    }),
});

export const getShelfItems = ({
  productionPlanId,
  search,
}: {
  productionPlanId: string;
  search: string;
}): ApiFetcher<ShelfItem[]> => ({
  key: productionPlansKeys.shelfItems(productionPlanId, search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/manufacturing/manufacturing-items/${productionPlanId}/shelf` + search,
    }),
});

export const getShelfItemDetails = (
  hackSearch: string = "",
): ApiFetcher<Pagination<ShelfManufacturingItem>> => ({
  key: productionPlansKeys.shelfItemDetails(hackSearch.split("$")[1]),
  fetcher: () => {
    const [productionPlanId, search] = hackSearch.split("$");
    return queryFetch({
      method: "GET",
      url: `/manufacturing/manufacturing-items/${productionPlanId}/shelf/entries` + search,
    });
  },
});

export const getProductionPlan = (id: string): ApiFetcher<ProductionPlan> => ({
  key: productionPlansKeys.productionPlan(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/manufacturing/stage-production-plans/plan/${id}/details`,
    }),
  globalOptions: {
    enabled: Boolean(id),
  },
});

export const patchProductionPlanOnDetails = (data: PartialOf<ProductionPlan>, id: UUID | number) =>
  queryFetch<ProductionPlan>({
    method: "PATCH",
    url: "/manufacturing/stage-production-plans/entries/" + id,
    data,
  });

export const postProductionPlan = (data: PostProductionPlan) =>
  queryFetch<ProductionPlanListItem>({
    method: "POST",
    url: "/manufacturing/stage-production-plans/entries",
    data,
  });

export const deleteProductionPlan = (id: string) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/manufacturing/stage-production-plans/entries/" + id,
  });

export const patchProductionPlanOnList = (data: Partial<ProductionPlanListItem>, id: string) =>
  queryFetch<ProductionPlanListItem>({
    method: "PATCH",
    url: "/manufacturing/stage-production-plans/entries/" + id,
    data,
  });

export const getManufacturingSchemaStages = (
  search: string = "",
): ApiFetcher<Pagination<ManufacturingSchema>> => ({
  key: productionPlansKeys.manufacturingSchemaStages(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/schemas/entries" + search,
    }),
});

export const getSchemaStages = (search: string = ""): ApiFetcher<Pagination<SchemaStage>> => ({
  key: productionPlansKeys.schemaStages(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/schemas/stages" + search,
    }),
});

export const removeEmployeeFromProductionPlan = (data: AddOrRemoveEmployeesFromProductionPlan) =>
  queryFetch<string>({
    method: "POST",
    url: "/manufacturing/employee-work-days/remove-employees-in-a-plan",
    data,
  });

export const addEmployeesToProductionPlan = (data: AddOrRemoveEmployeesFromProductionPlan) =>
  queryFetch<string>({
    method: "POST",
    url: "/manufacturing/employee-work-days/add-employees-to-a-plan",
    data,
  });

export const removeWorkingDayFromProductionPlan = (data: AddOrRemoveWorkingDaysInProductionPlan) =>
  queryFetch<string>({
    method: "POST",
    url: "/manufacturing/employee-work-days/remove-working-days-in-a-plan",
    data,
  });

export const addWorkingDaysToProductionPlan = (data: AddOrRemoveWorkingDaysInProductionPlan) =>
  queryFetch<string>({
    method: "POST",
    url: "/manufacturing/employee-work-days/add-working-days-to-a-plan",
    data,
  });
