import localStyles from "../CargoUnloadsSection.module.css";
import styles from "../../../Dashboard.module.css";
import cx from "classnames";
import { CargoUnload as CargoUnloadI, cargoUnloadStatusDict } from "api/wms/models";
import { MoreDataCounter } from "components/common/moreDataCounter";
import { StateLabel } from "components/common/stateLabel";
import { LoadingTimeLabel } from "pages/wms/dashboard/shared/loadingTimeLabel/LoadingTimeLabel";

interface Props {
  cargoUnload: CargoUnloadI;
  index: number;
}

export const CargoUnload = ({ cargoUnload, index }: Props) => {
  return (
    <div
      className={cx(styles.tableRow, localStyles.tableRowWHNearestUnloading, {
        [styles.rowCurrent]: cargoUnload.status === "STARTED",
      })}
    >
      <div>{index + 1}.</div>
      <div className={cx(localStyles.signature, "overflow")}>{cargoUnload.signature}</div>
      <div className="overflow">
        {cargoUnload.customers.slice(0, 1).map(customer => {
          return customer ? customer.name : "";
        })}{" "}
        &nbsp;
        {cargoUnload.customers.length - 1 > 0 && (
          <MoreDataCounter
            counter={cargoUnload.customers.length - 1}
            style={{ width: "max-content", maxHeight: "18px" }}
          />
        )}
      </div>
      <div className="d-flex align-items-center justify-content-center">
        {cargoUnload.ramp ? (
          <StateLabel
            kind="default"
            style={{
              backgroundColor: cargoUnload.ramp.codeBackgroundColor ?? "#fff",
              maxWidth: "18.74px",
            }}
          >
            <div style={{ color: cargoUnload.ramp.codeTextColor ?? "#000" }}>
              {cargoUnload.ramp.code}
            </div>
          </StateLabel>
        ) : (
          "--"
        )}
      </div>
      <div className="d-flex align-items-center justify-content-end">
        {cargoUnload.estimatedTimeOfArrival ? cargoUnload.estimatedTimeOfArrival.slice(0, 5) : "--"}
      </div>
      <div className="d-flex align-items-center">
        <LoadingTimeLabel
          estimatedTime={cargoUnload.estimatedTimeOfArrival}
          startedAt={cargoUnload.startedAt}
          status={cargoUnload.status}
        />
      </div>
      <div>
        <StateLabel kind={cargoUnloadStatusDict[cargoUnload.status].labelKind}>
          {cargoUnloadStatusDict[cargoUnload.status].label}
        </StateLabel>
      </div>
    </div>
  );
};
