import { UUID } from "api/types";
import { colorType } from "components/common/stateLabel";

export type CargoUnloadTab = "not-started" | "on-going" | "finished" | "all";

export type CargoUnloadStatus = "NOT_STARTED" | "STARTED" | "FINISHED";

export const cargoUnloadStatusDict: Record<
  CargoUnloadStatus,
  { label: string; labelKind: colorType }
> = {
  NOT_STARTED: { label: "nierozpoczęty", labelKind: "black6" },
  STARTED: { label: "w toku", labelKind: "transparentViolet" },
  FINISHED: { label: "zakończony", labelKind: "teal6" },
};

export interface CargoUnloadRamp {
  code: string;
  codeBackgroundColor: string;
  codeTextColor: string;
  id: UUID;
  name: string;
}

export interface CargoUnload {
  customers: {
    id: string | number;
    name: string;
  }[];
  estimatedTimeOfArrival: string | null;
  finishedAt: string | null;
  id: UUID;
  orders: {};
  pauseEnd: string | null;
  pauseStart: string | null;
  ramp: CargoUnloadRamp | null;
  route: {};
  signature: string;
  startedAt: string | null;
  status: CargoUnloadStatus;
  users: any[];
}
