import { CargoUnloadStatus } from "api/wms/models";
import { StateLabel } from "components/common/stateLabel";
import { convertMinutesToHoursAndMinutes } from "utilities/convertMinutesToHoursAndMinutes";
import { getCurrentLoadingsTime } from "./utils/getCurrentLoadingsTime";
import { getFutureLoadingsTime } from "./utils/getFutureLoadingsTime";

interface Props {
  estimatedTime: string | null;
  startedAt: string | null;
  status: CargoUnloadStatus;
}

export const LoadingTimeLabel = ({ estimatedTime, startedAt, status }: Props) => {
  if (status === "FINISHED") {
    return <StateLabel kind="bgTeal2">rozładowano</StateLabel>;
  }

  if (status === "STARTED") {
    return (
      <StateLabel kind="deepPurple">
        trwa od{" "}
        {startedAt && convertMinutesToHoursAndMinutes(getCurrentLoadingsTime(startedAt), true)}
      </StateLabel>
    );
  }

  if (status === "NOT_STARTED" && estimatedTime) {
    const { status, minutes } = getFutureLoadingsTime(estimatedTime);

    if (status === "EARLY" && minutes <= 60) {
      return (
        <StateLabel kind="bgYellow2">
          pozostało {convertMinutesToHoursAndMinutes(minutes, true)}
        </StateLabel>
      );
    }

    if (status === "EARLY" && minutes > 60) {
      return (
        <StateLabel kind="bgBlack5">
          pozostało {convertMinutesToHoursAndMinutes(minutes, true)}
        </StateLabel>
      );
    }

    if (status === "LATE") {
      return (
        <StateLabel kind="bgRed2">
          spóźniony o {convertMinutesToHoursAndMinutes(minutes, true)}
        </StateLabel>
      );
    }
  }

  return <StateLabel kind="default">brak godz.</StateLabel>;
};
