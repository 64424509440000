import { ProductionPlanListItem, productionPlanStatusDict } from "api/new-production-plans/models";
import { HighlightedRow } from "api/other/models";
import { UUID } from "api/types";
import { ModuleRow } from "components/utils/moduleList/components/ModuleRow";
import cx from "classnames";
import styles from "../ManufacturingPlansList.module.css";
import { productionPlansListConfig } from "components/utils/moduleList/utils/moduleListConfig";
import { dateFns } from "utilities";
import { useProductionPlanOnListPatchMutation } from "api/new-production-plans/hooks";
import { CheckboxGreen } from "components/utils/checkboxGreen";

interface Props {
  highlightedRows: HighlightedRow[];
  index: number;
  isActive: (id: string) => boolean;
  isHighlighted: (id: string) => boolean;
  productionPlan: ProductionPlanListItem;
  selectMutlipleItems: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: UUID | number,
    index: number,
    togglePanel: () => void,
  ) => void;
  togglePanel: (id: string) => void;
  search: string;
}

export const ListItem = ({
  highlightedRows,
  index,
  isActive,
  isHighlighted,
  productionPlan,
  selectMutlipleItems,
  togglePanel,
  search,
}: Props) => {
  const patchMutation = useProductionPlanOnListPatchMutation();

  return (
    <>
      <ModuleRow
        className={cx("nowrap", styles.fixedSingleRouteRow, {
          active: isActive(String(productionPlan.id)),
          highlighted:
            isHighlighted(String(productionPlan.id)) ||
            highlightedRows.some(row => row.id === productionPlan.id),
        })}
        gridStyle={productionPlansListConfig.grid}
        onMouseDown={event =>
          selectMutlipleItems(event, productionPlan.id, index, () =>
            togglePanel(String(productionPlan.id)),
          )
        }
      >
        <div className="d-flex align-items-center" onMouseDown={e => e.stopPropagation()}>
          <CheckboxGreen
            checked={productionPlan.isConfirmed}
            disabled={patchMutation.isLoading}
            name="isRequired"
            onChange={() => {
              patchMutation.mutate({
                id: productionPlan.id,
                toUpdate: { isConfirmed: !productionPlan.isConfirmed },
              });
            }}
          />
        </div>

        <div>{productionPlan.signature.length > 0 ? productionPlan.signature : "--"}</div>

        <div>{productionPlan.name.length > 0 ? productionPlan.name : "--"}</div>

        <div>
          {productionPlan.rangeOfDates.firstDate.length > 0
            ? dateFns.format(new Date(productionPlan.rangeOfDates.firstDate), "dd MMMM")
            : "--"}
        </div>

        <div>
          {productionPlan.rangeOfDates.lastDate.length > 0
            ? dateFns.format(new Date(productionPlan.rangeOfDates.lastDate), "dd MMMM")
            : "--"}
        </div>

        <div>
          {productionPlan.status.length > 0
            ? productionPlanStatusDict[productionPlan.status]
            : "--"}
        </div>
      </ModuleRow>
    </>
  );
};
