import { HighlightedRow } from "api/other/models";
import { UUID } from "api/types";
import { useCargoUnloads } from "api/wms/hooks";
import { CargoUnloadTab } from "api/wms/models";
import { InvoicePagination } from "components/common/invoicePagination/InvoicePagination";
import { CommonError, RightPanelHandler } from "components/utils";
import { ListDrawerWrapper } from "components/utils/drawer";
import { Loader } from "components/utils/loader";
import { ModuleList } from "components/utils/moduleList";
import { ModuleListBody } from "components/utils/moduleList/components/ModuleListBody";
import { ModuleListHeader } from "components/utils/moduleList/components/ModuleListHeader";
import { wmsCargoUnloadsListConfig } from "components/utils/moduleList/utils/moduleListConfig";
import { NoResults } from "components/utils/noResults";
import { useQuery } from "hooks";
import { useState } from "react";
import { RouteComponentProps } from "react-router";
import { handleMultipleRowsHighlight } from "utilities/handleMultipleRowsHighlight";
import { ListHeader } from "./components/ListHeader";
import { ListItem } from "./components/ListItem";
import { getSearch } from "./utils/getSearch";

export const UnloadingsList = ({ match }: RouteComponentProps<{ tab: CargoUnloadTab }>) => {
  const { tab } = match.params;
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = getSearch({ query, tab });
  const { data: cargoUnloads, error, isFetching, isLoading, pagination } = useCargoUnloads(search);

  // show Loader

  const [highlightedRows, setHighlightedRows] = useState<HighlightedRow[]>([]);

  const noResults = !cargoUnloads.length && !isLoading && !error;

  if (error) {
    return (
      <div>
        <ListHeader />
        <div className="mt-4">
          <CommonError status={error._httpStatus_} />
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <Loader module="wms" />
        <ListHeader />
      </div>
    );
  }

  const selectMutlipleItems = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: UUID | number,
    index: number,
    togglePanel: () => void,
  ) => {
    handleMultipleRowsHighlight(
      event,
      highlightedRows,
      id,
      index,
      setHighlightedRows,
      togglePanel,
      cargoUnloads,
    );
  };

  return (
    <div className="position-relative h-100">
      {isFetching && <Loader module="logistics" />}
      <ListHeader />
      <RightPanelHandler name="wmsCargoUnloads">
        {({ close, isActive, isHighlighted, togglePanel }) => (
          <ListDrawerWrapper>
            {noResults ? (
              <NoResults on={noResults} subHeader="rozładunki" />
            ) : (
              <ModuleList>
                <ModuleListHeader
                  gridStyle={wmsCargoUnloadsListConfig.grid}
                  labels={wmsCargoUnloadsListConfig.labels}
                />
                <ModuleListBody>
                  {cargoUnloads.map((cargoUnload, index) => (
                    <ListItem
                      cargoUnload={cargoUnload}
                      highlightedRows={highlightedRows}
                      index={index}
                      isActive={isActive}
                      isHighlighted={isHighlighted}
                      key={cargoUnload.id}
                      selectMutlipleItems={selectMutlipleItems}
                      togglePanel={togglePanel}
                    />
                  ))}
                </ModuleListBody>
              </ModuleList>
            )}
            {/* RightPanel */}
            <InvoicePagination pagination={pagination} />
            {/* ActionToolbar */}
          </ListDrawerWrapper>
        )}
      </RightPanelHandler>
    </div>
  );
};
