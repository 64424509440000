import { Pagination } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { wmsKeys } from "./keys";
import { CargoUnload } from "./models";

export const getCargoUnloads = (search: string = ""): ApiFetcher<Pagination<CargoUnload>> => ({
  key: wmsKeys.cargoUnloads.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wms/cargo-unloads/items" + search,
    }),
});
