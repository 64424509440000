import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelSection } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useEffect, useState } from "react";
import { PanelHeader } from "./components/PanelHeader";
import { useManufacturingStage } from "api/manufacturingNew/hooks";
import { InfoLabel } from "components/common/infoLabel";

interface Props {
  close: () => void;
}

export const RightPanel = ({ close }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const [isScrolledOver, setIsScrolledOver] = useState(false);
  const { data: manufacturingStage, error, isLoading } = useManufacturingStage(panelId, {
    enabled: Boolean(panelId),
  });

  useEffect(() => {
    setIsScrolledOver(false);
  }, [panelId]);

  if (!manufacturingStage) return null;

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  return (
    <DrawerRightPanel key={manufacturingStage.id}>
      <PanelHeader
        manufacturingStage={manufacturingStage}
        panelId={panelId}
        close={close}
        isScrolledOver={isScrolledOver}
      />
      <DisabledOpacity disabled={isLoading}>
        <RightPanelSection>
          <div className="fs-24 mb-2">{manufacturingStage.name}</div>
          <InfoLabel title="dodane przez">
            <div className="body-14-500">
              {manufacturingStage.implementedBy
                ? manufacturingStage.implementedBy === "IN_HOUSE"
                  ? "wewnętrznie"
                  : "kontraktora"
                : "---"}
            </div>
          </InfoLabel>
        </RightPanelSection>
      </DisabledOpacity>
    </DrawerRightPanel>
  );
};
