import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import favorite from "assets/images/moduleNavigationIcons/favorite.svg";
import { Dashboard } from "routes/inventory/Dashboard";

export const inventoryPopularity: ModuleLink = {
  url: "inventory-popularity",
  label: "Popularność produktów",
  icon: favorite,
  subSection: <></>,
  routing: Dashboard,
};
