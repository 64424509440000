import { FormInput, Modal } from "components/utils";
import { Formik, FormikHelpers } from "formik";
import { getAnyErrorKey } from "utilities";
import cx from "classnames";
import { Button, useToastr } from "components/common";
import { useMutation } from "hooks/useMutation";
import { postManufacturingStage } from "api/manufacturingNew/calls";
import { PostManufacturingStage } from "api/manufacturingNew/models";
import { useQueryClient } from "react-query";
import { manufacturingNewKeys } from "api/manufacturingNew/keys";
import { getRandomColor } from "utilities/getRandomColor";

interface Props {
  close: () => void;
}

const initialValues: PostManufacturingStage = {
  name: "",
  textColor: "",
  backgroundColor: "",
};

export const CreateManufacturingStageModal = ({ close }: Props) => {
  const { backgroundColor, textColor } = getRandomColor();
  const toastr = useToastr();
  const queryClient = useQueryClient();
  const createManufacturingStageMutation = useMutation(
    ({
      values,
    }: {
      values: PostManufacturingStage;
      actions: FormikHelpers<PostManufacturingStage>;
    }) => {
      return postManufacturingStage(values);
    },
    {
      onSuccess: payload => {
        close();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Utworzono nowy etap produkcji: ${payload.name}`,
        });
        queryClient.invalidateQueries(manufacturingNewKeys.manufacturingStages());
      },
      onError: (error, { actions }) => {
        actions.setErrors(error);
        actions.setSubmitting(false);
        toastr.open({
          type: "warning",
          title: "Wymagane działanie...",
          text: getAnyErrorKey(error),
        });
      },
    },
  );

  return (
    <Modal
      close={close}
      isOpen={true}
      overrides={{ container: { className: "p-3" } }}
      size={{ all: { width: "480px" } }}
    >
      <>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            createManufacturingStageMutation.mutate({
              values: { ...values, textColor, backgroundColor },
              actions,
            });
          }}
        >
          {({ handleSubmit, isSubmitting, isValid }) => (
            <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
              <div className="font-bold fs-18 mb-4">Utwórz etap produkcji</div>

              <FormInput className="mb-4" label="Nazwa etapu" name="name" />
              <div className="d-flex align-items-center mt-4 ">
                <Button
                  className="mr-3 fs-12 uppercase"
                  disabled={isSubmitting}
                  kind="primary"
                  type="submit"
                >
                  Utwórz
                </Button>
                <Button className="fs-12 uppercase" kind="secondary-stroke" onClick={close}>
                  Anuluj
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </>
    </Modal>
  );
};
