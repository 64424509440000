import { Module } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import styles from "./Loader.module.css";
import cx from "classnames";

interface Props {
  animationDuration?: string;
  className?: string;
  module: Module;
}

const loaderColorDict: Record<Module, string> = {
  callCenter: "#55bca6",
  finances: "#b65eff",
  account: "8c73ff",
  sales: "8c73ff",
  manufacturing: "#ec8a28",
  logistics: "#8c73ff",
  wms: "#e4b600",
  reports: "8c73ff",
  crm: "8c73ff",
  humanResources: "8c73ff",
  inventory: "8c73ff",
  support: "8c73ff",
  rules: "8c73ff",
};

export const Loader = ({ animationDuration, className, module }: Props) => {
  return (
    <div className={cx(styles.loaderBarWrapper, className)}>
      <div className={styles.loaderBar}>
        <div
          className={cx(styles.loaderBarFill, animationDuration)}
          style={{ background: loaderColorDict[module] }}
        />
      </div>
    </div>
  );
};
