import { useQueryUtils } from "hooks";
import { createApiQuery } from "hooks/createApiQuery";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { useMutation } from "hooks/useMutation";
import { PartialOf } from "typeUtilities";
import { parsePatchData } from "utilities/parsePatchData";
import {
  getManufacturingSchemaStages,
  getProductionPlan,
  getProductionPlans,
  getSchemaStages,
  getShelfItemDetails,
  getShelfItems,
  patchProductionPlanOnDetails,
  patchProductionPlanOnList,
} from "./calls";
import { productionPlansKeys } from "./keys";
import { ProductionPlan, ProductionPlanListItem } from "./models";

export const useProductionPlans = createPaginatedApiQuery(getProductionPlans);
export const useProductionPlan = createApiQuery(getProductionPlan);

export const useManufacturingSchemaStages = createPaginatedApiQuery(getManufacturingSchemaStages);
export const useSchemaStages = createPaginatedApiQuery(getSchemaStages);

export const useShelfItems = createApiQuery(getShelfItems);
export const useShelfItemDetails = createPaginatedApiQuery(getShelfItemDetails);

export const useProductionPlanOnListPatchMutation = (parseData: boolean = false) => {
  const { rollback, handlePaginatedListUpdate, rollbackList } = useQueryUtils();

  return useMutation(
    ({ id, toUpdate }: { id: string; toUpdate: PartialOf<ProductionPlanListItem> }) => {
      if (parseData) {
        return patchProductionPlanOnList(parsePatchData(toUpdate), id);
      }
      return patchProductionPlanOnList(toUpdate, id);
    },
    {
      onMutate: ({ id, toUpdate }) => {
        const prevList = handlePaginatedListUpdate(
          productionPlansKeys.productionPlans(),
          id,
          toUpdate,
        );

        return { prevList };
      },
      onError: (error, { id }, onMutateReturn) => {
        const { prevList } = onMutateReturn as { prevList: any };
        rollback(productionPlansKeys.productionPlan(String(id)), error);
        rollbackList(productionPlansKeys.productionPlans(), prevList, id);
      },
    },
  );
};

export const useProductionPlanDetailsPatchMutation = (
  setShowLoader?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const { handleMutate, rollback } = useQueryUtils();

  return useMutation(
    ({ id, toUpdate }: { id: string | number; toUpdate: PartialOf<ProductionPlan> }) =>
      patchProductionPlanOnDetails(parsePatchData(toUpdate), id),
    {
      onMutate: ({ id, toUpdate }) => {
        if (setShowLoader) {
          setShowLoader(true);
        }
        const prevDetails = handleMutate(productionPlansKeys.productionPlan(String(id)), toUpdate);
        return { prevDetails };
      },
      onError: (error, { id }, onMutateReturn) => {
        const { prevDetails } = onMutateReturn as { prevDetails: ProductionPlan };
        rollback(productionPlansKeys.productionPlan(String(id)), prevDetails, error);
      },
    },
  );
};
