import { Header } from "./components/Header";
import localStyles from "./Dashboard.module.css";
import cx from "classnames";
import { CargoUnloadsSection } from "./components/cargoUnloadsSection/CargoUnloadsSection";

export const Dashboard = () => {
  return (
    <div className="position-relative">
      <Header />
      <div className={localStyles.mainContent}>
        <div className={cx(localStyles.mainContentInner, "flex-wrap")}>
          <div
            className={cx(
              localStyles.dashboardScrollableContent,
              "d-flex align-items-start flex-wrap w-100 py-3",
            )}
          >
            <div className="col-12 col-xxl-5 pr-3 pr-xxl-0">
              <CargoUnloadsSection />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
