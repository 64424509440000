import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import badge from "assets/images/moduleNavigationIcons/badge.svg";
import { Dashboard } from "routes/humanResources/Dashboard";

export const employees: ModuleLink = {
  url: "employees",
  label: "Pracownicy",
  icon: badge,
  subSection: <></>,
  routing: Dashboard,
};
