import chairBlue from "assets/images/moduleNavigationIcons/chair-blue.svg";
import inventoryIcon from "assets/images/products.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import { dashboard } from "./routes/dashboard";
import { products } from "./routes/products";
import { attributes } from "./routes/attributes";
import { indexes } from "./routes/indexes";
import { assortment } from "./routes/assortment";
import { inventoryPopularity } from "./routes/inventory-popularity";
import { priceLists } from "./routes/price-lists";
import { miloOffers } from "./routes/milo-offers";

export const inventory: ModuleNavigation = {
  labelBackground: "#01D6FB",
  label: "Produkty",
  iconBackground: "#005968",
  icon: chairBlue,
  menuIcon: inventoryIcon,
  name: "INVENTORY",
  url: "/inventory",
  navigationSections: [
    {
      navigationList: [
        dashboard,
        products,
        indexes,
        attributes,
        assortment,
        priceLists,
        inventoryPopularity,
        miloOffers,
      ],
    },
  ],
};
