import { useTradingDocuments } from "api/trading-documents/hooks";
import { CommonError, RightPanelHandler } from "components/utils";
import { ListDrawerWrapper } from "components/utils/drawer";
import { useQuery } from "hooks";
import { RouteComponentProps } from "react-router";
import { ModuleList } from "components/utils/moduleList";
import { ModuleListHeader } from "components/utils/moduleList/components/ModuleListHeader";
import { ModuleListBody } from "components/utils/moduleList/components/ModuleListBody";
import { salesInvoiceListConfig } from "components/utils/moduleList/utils/moduleListConfig";
import { ListHeader } from "./components/ListHeader";
import { ListItem } from "./components/ListItem";
import { Tab } from "api/trading-documents/models";
import { getSearch } from "../shared/utils/getSearch";
import { RightPanel } from "./rightPanel/RightPanel";
import { InvoicePagination } from "components/common/invoicePagination/InvoicePagination";
import { useState } from "react";
import { UUID } from "api/types";
import { HighlightedRow } from "api/other/models";
import { handleMultipleRowsHighlight } from "utilities/handleMultipleRowsHighlight";
import { ActionToolbar } from "../shared/components/actionToolbar/ActionToolbar";
import { NoResults } from "components/utils/noResults";
import { Loader } from "components/utils/loader";

export const SalesInvoicesList = ({ match }: RouteComponentProps<{ tab: Tab }>) => {
  const { tab } = match.params;
  const { query } = useQuery({ exclude: ["panelId"] });
  const type = "INVOICE";
  const invoiceType = "SALES";
  const additionalListParams = {
    type,
    invoiceType,
  };
  const search = getSearch({ query, type, invoiceType, tab });
  const {
    data: tradingDocuments,
    error,
    isFetching,
    isLoading,
    refetch,
    pagination,
  } = useTradingDocuments(search);
  const [highlightedRows, setHighlightedRows] = useState<HighlightedRow[]>([]);

  const [showLoader, setShowLoader] = useState(false);

  const noResults = !tradingDocuments.length && !isLoading && !error;

  if (error) {
    return (
      <div>
        <ListHeader
          additionalListParams={additionalListParams}
          refetchList={refetch}
          search={search}
        />
        <div className="mt-4">
          <CommonError status={error._httpStatus_} />
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div>
        <Loader module="finances" />
        <ListHeader
          additionalListParams={additionalListParams}
          refetchList={refetch}
          search={search}
        />
      </div>
    );
  }

  const selectMutlipleItems = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: UUID,
    index: number,
    togglePanel: () => void,
  ) => {
    handleMultipleRowsHighlight(
      event,
      highlightedRows,
      id,
      index,
      setHighlightedRows,
      togglePanel,
      tradingDocuments,
    );
  };

  return (
    <div className="pageContainer">
      {(showLoader || isFetching) && <Loader module="finances" />}
      <ListHeader
        additionalListParams={additionalListParams}
        refetchList={refetch}
        search={search}
      />
      <RightPanelHandler name="salesInvoices">
        {({ close, isActive, isHighlighted, togglePanel }) => (
          <ListDrawerWrapper>
            {noResults ? (
              <NoResults on={noResults} subHeader="faktury sprzedażowe" />
            ) : (
              <ModuleList>
                <ModuleListHeader
                  gridStyle={salesInvoiceListConfig.grid}
                  labels={salesInvoiceListConfig.labels}
                />
                <ModuleListBody>
                  {tradingDocuments.map((tradingDocument, index) => (
                    <ListItem
                      highlightedRows={highlightedRows}
                      index={index}
                      isActive={isActive}
                      isHighlighted={isHighlighted}
                      key={tradingDocument.id}
                      togglePanel={togglePanel}
                      salesInvoice={tradingDocument}
                      selectMutlipleItems={selectMutlipleItems}
                    />
                  ))}
                </ModuleListBody>
              </ModuleList>
            )}
            <RightPanel close={close} showLoader={showLoader} setShowLoader={setShowLoader} />
            <InvoicePagination pagination={pagination} />
            {highlightedRows.length > 0 && (
              <ActionToolbar
                actualPage={query.page}
                close={() => {
                  setHighlightedRows([]);
                }}
                tradingDocument={{
                  type: type,
                  invoiceType: invoiceType,
                }}
                quantity={highlightedRows.length}
                highlightedRows={highlightedRows}
                setHighlightedRows={setHighlightedRows}
              />
            )}
          </ListDrawerWrapper>
        )}
      </RightPanelHandler>
    </div>
  );
};
