import { CargoUnload } from "api/wms/models";
import { getLoadingTime } from "pages/wms/shared/utils/getLoadingTime";
import { convertMinutesToHoursAndMinutes } from "utilities/convertMinutesToHoursAndMinutes";

interface Props {
  cargoUnload: CargoUnload;
}

export const CargoUnloadDuration = ({ cargoUnload }: Props) => {
  if (cargoUnload.status === "FINISHED" && cargoUnload.startedAt && cargoUnload.finishedAt) {
    return (
      <div className="fw-700">
        {convertMinutesToHoursAndMinutes(
          getLoadingTime(cargoUnload.startedAt, cargoUnload.finishedAt),
        )}
      </div>
    );
  }

  if (cargoUnload.status === "STARTED" && cargoUnload.startedAt) {
    return (
      <div className="fw-700">
        {convertMinutesToHoursAndMinutes(getLoadingTime(cargoUnload.startedAt))}
      </div>
    );
  }

  return <div>---</div>;
};
