import { Button } from "components/common";
import arrowRightIcon from "assets/images/arrowForward.svg";
import { useFilters } from "hooks/useFilters";
import { queryString } from "utilities";
import { useCargoUnloads } from "api/wms/hooks";
import { CommonError } from "components/utils";
import { Loader } from "components/utils/loader";
import localStyles from "./CargoUnloadsSection.module.css";
import styles from "../../Dashboard.module.css";
import cx from "classnames";
import { CargoUnload } from "./components/CargoUnload";
import { Link } from "react-router-dom";

export type CargoUnloadsFilters = {
  page: string;
  pageSize: string | number;
  search: string;
};

const initialFilters: CargoUnloadsFilters = {
  page: "1",
  pageSize: "7",
  search: "",
};

export const CargoUnloadsSection = () => {
  const { filters } = useFilters<CargoUnloadsFilters>(initialFilters);
  const search = queryString.stringify(filters);

  const { data: cargoUnloads, error, isLoading } = useCargoUnloads(search);

  if (error) {
    return (
      <div className={styles.card}>
        <div className="mt-4">
          <CommonError status={error._httpStatus_} />
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={styles.card}>
        <Loader module="wms" />
      </div>
    );
  }

  return (
    <div className={styles.card}>
      <div>
        <div className="d-flex align-items-center justify-content-between w-100 gap-1 px-3 py-3">
          <h3 className={styles.sectionTitle}>Najbliższe rozładunki</h3>
          <Button as={Link} className={styles.allBtn} kind="create" to="/wms/unloadings/list/all">
            <div className="btnBase btnBaseSmall">
              Wszystkie
              <img alt="" src={arrowRightIcon} />
            </div>
          </Button>
        </div>
        <div className="pb-2" style={{ overflowX: "scroll" }}>
          <div className={cx(styles.tableHeader, localStyles.tableHeaderWHNearestUnloading)}>
            <div>#</div>
            <div>sygnatura</div>
            <div className="overflow">producenci</div>
            <div className="d-flex justify-content-center">rampa</div>
            <div className="d-flex justify-content-end">przyjazd</div>
            <div />
            <div>status</div>
          </div>
          {cargoUnloads.map((cargoUnload, index) => (
            <CargoUnload cargoUnload={cargoUnload} index={index} key={cargoUnload.id} />
          ))}
        </div>
      </div>
    </div>
  );
};
