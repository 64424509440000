import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import accountCircle from "assets/images/moduleNavigationIcons/account_circle.svg";
import { Dashboard } from "routes/humanResources/Dashboard";

export const users: ModuleLink = {
  url: "users",
  label: "Użytkownicy",
  icon: accountCircle,
  subSection: <></>,
  routing: Dashboard,
};
