import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelSection } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useEffect, useState } from "react";
import { PanelHeader } from "./components/PanelHeader";
import { useManufacturingSchema } from "api/manufacturingNew/hooks";

interface Props {
  close: () => void;
}

export const RightPanel = ({ close }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const [isScrolledOver, setIsScrolledOver] = useState(false);
  const { data: manufacturingSchema, error, isLoading } = useManufacturingSchema(panelId, {
    enabled: Boolean(panelId),
  });

  useEffect(() => {
    setIsScrolledOver(false);
  }, [panelId]);

  if (!manufacturingSchema) return null;

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  return (
    <DrawerRightPanel key={manufacturingSchema.id}>
      <PanelHeader
        manufacturingSchema={manufacturingSchema}
        panelId={panelId}
        close={close}
        isScrolledOver={isScrolledOver}
      />
      <DisabledOpacity disabled={isLoading}>
        <RightPanelSection>
          <div className="fs-24">{manufacturingSchema.name}</div>
        </RightPanelSection>
      </DisabledOpacity>
    </DrawerRightPanel>
  );
};
