import { FilterType } from "components/common/filters/types";
import { FilterList } from "components/common/moduleNavigation/components/filtersSection/filterList/FilterList";
import { useRefFn } from "hooks";
import { createDateRanges } from "utilities";
import { getLastFiveDaysForFilters } from "utilities/getLastFiveDaysForFilters";

export const RoutesSubSection = () => {
  const dateRanges = useRefFn(() => createDateRanges()).current;

  return (
    <FilterList
      filters={[
        {
          type: FilterType.Select,
          label: "sortuj",
          name: "ordering",
          options: [
            { label: "Od ostatnio edytowanych", value: "-lastModified" },
            { label: "Od najnowszych", value: "-created" },
          ],
        },
        {
          type: FilterType.Date,
          label: "utworzono",
          name: "created",
          options: getLastFiveDaysForFilters(),
        },
        {
          type: FilterType.Date,
          label: "data wyjazdu",
          name: "departureDate",
          options: getLastFiveDaysForFilters(),
        },
        {
          type: FilterType.DateRangeWithOptions,
          label: "zakres dat utworzenia",
          name: ["minCreatedDate", "maxCreatedDate"],
          options: [
            { label: "Ten miesiąc", value: dateRanges.thisMonth },
            { label: "Ostatni miesiąc", value: dateRanges.lastMonth },
            { label: "Ostatni pełny miesiąc", value: dateRanges.lastFullMonth },
            { label: "Ostatnie 6 miesięcy", value: dateRanges.lastSixMonths },
            { label: "Ostatnie 6 pełnych miesięcy", value: dateRanges.lastSixFullMonths },
          ],
        },
        {
          type: FilterType.SelectUser,
          label: "kierowca",
          multiple: true,
          name: "drivers",
          searchBy: "drivers",
        },
      ]}
    />
  );
};
