import { HighlightedRow } from "api/other/models";
import { UUID } from "api/types";
import { CargoUnload } from "api/wms/models";
import { ModuleRow } from "components/utils/moduleList/components/ModuleRow";
import styles from "../UnloadingsList.module.css";
import cx from "classnames";
import { wmsCargoUnloadsListConfig } from "components/utils/moduleList/utils/moduleListConfig";
import { MoreDataCounter } from "components/common/moreDataCounter";
import { StateLabel } from "components/common/stateLabel";
import { dateFns } from "utilities";
import { CargoUnloadDuration } from "./CargoUnloadDuration";
import { CargoUnloadLabelDownload } from "./CargoUnloadLabelDownload";

interface Props {
  cargoUnload: CargoUnload;
  highlightedRows: HighlightedRow[];
  index: number;
  isActive: (id: string) => boolean;
  isHighlighted: (id: string) => boolean;
  selectMutlipleItems: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: UUID | number,
    index: number,
    togglePanel: () => void,
  ) => void;
  togglePanel: (id: string) => void;
}

export const ListItem = ({
  cargoUnload,
  highlightedRows,
  index,
  isActive,
  isHighlighted,
  selectMutlipleItems,
  togglePanel,
}: Props) => {
  return (
    <ModuleRow
      className={cx(styles.fixedListItemSize, {
        active: isActive(String(cargoUnload.id)),
        highlighted:
          isHighlighted(String(cargoUnload.id)) ||
          highlightedRows.some(row => row.id === cargoUnload.id),
      })}
      gridStyle={wmsCargoUnloadsListConfig.grid}
      onMouseDown={event =>
        selectMutlipleItems(event, cargoUnload.id, index, () => togglePanel(String(cargoUnload.id)))
      }
    >
      <div className="fw-700">BACKEND</div>
      <div className="overflow fw-700">
        {cargoUnload.customers.slice(0, 2).map((customer, index) => {
          if (cargoUnload.customers.length - index - 1 > 0) {
            return customer ? `${customer.name}, ` : "";
          }
          return customer ? customer.name : "";
        })}{" "}
        &nbsp;
        {cargoUnload.customers.length - 2 > 0 && (
          <MoreDataCounter
            counter={cargoUnload.customers.length - 2}
            style={{ width: "max-content", maxHeight: "18px" }}
          />
        )}
      </div>
      <div className={cx(styles.listSignature, "overflow")}>{cargoUnload.signature}</div>
      <div className="d-flex align-items-center justify-content-center">
        {cargoUnload.ramp ? (
          <StateLabel
            kind="default"
            style={{
              backgroundColor: cargoUnload.ramp.codeBackgroundColor ?? "#fff",
              maxWidth: "18.74px",
            }}
          >
            <div style={{ color: cargoUnload.ramp.codeTextColor ?? "#000" }}>
              {cargoUnload.ramp.code}
            </div>
          </StateLabel>
        ) : (
          "--"
        )}
      </div>
      <div className="d-flex align-items-center fw-700">
        {cargoUnload.estimatedTimeOfArrival ? cargoUnload.estimatedTimeOfArrival.slice(0, 5) : "--"}
      </div>
      <div className="d-flex align-items-center">BACKEND</div>
      <div>
        {cargoUnload.status === "FINISHED" && cargoUnload.finishedAt
          ? dateFns.format(new Date(cargoUnload.finishedAt), "dd.MM.yyyy, HH:mm")
          : "---"}
      </div>

      <CargoUnloadDuration cargoUnload={cargoUnload} />

      <CargoUnloadLabelDownload cargoUnload={cargoUnload} index={index} />
    </ModuleRow>
  );
};
