import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import payments from "assets/images/moduleNavigationIcons/payments.svg";
import { Dashboard } from "routes/inventory/Dashboard";

export const priceLists: ModuleLink = {
  url: "price-lists",
  label: "Cenniki",
  icon: payments,
  subSection: <></>,
  routing: Dashboard,
};
