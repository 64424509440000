import { useTradingDocument } from "api/trading-documents/hooks";
import { RightPanelHeader } from "components/utils/drawer";
import { useQuery } from "hooks";
import { Button } from "components/common";
import checkIcon from "assets/images/check_small.svg";
import downloadPdfIcon from "assets/images/download_pdf.svg";
import downloadXmlIcon from "assets/images/downloadXml.svg";
import hidePanelIcon from "assets/images/keyboardTab.svg";
import { useHistory } from "react-router-dom";
import { handleDownloadOrderXml } from "pages/tradingDocuments/shared/utils/handleDownloadOrderXml";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { MoreInfoButton } from "pages/tradingDocuments/shared/components/rightPanel/moreInfoButton/MoreInfoButton";

interface Props {
  close: () => void;
}

export const PanelHeader = ({ close }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const history = useHistory();
  const { data: purchaseInvoice } = useTradingDocument({ id: panelId });

  if (!purchaseInvoice) return null;

  return (
    <RightPanelHeader>
      <div>
        <Button
          kind="create"
          onClick={e => {
            e.stopPropagation();
            history.push(`/finances/import-invoices/check?id=${purchaseInvoice.id}`, {
              fromMyInvoices: `${history.location.pathname}${history.location.search}`,
            });
          }}
          title="Zatwierdź fakturę"
        >
          <div className="btnBase btnBaseSmall">
            <img alt="Gotowe" src={checkIcon} style={{ height: "16px", width: "16px" }} />
            Przejdź do zatwierdzania
          </div>
        </Button>
      </div>
      <div className="d-flex align-items-center justify-content-end gap-1">
        <Button
          kind="transparent-black"
          onClick={() => {
            handleDownloadOrderXml(purchaseInvoice, downloadFeedbackToastr);
          }}
          size="square-s"
          title="Pobierz XML z danymi faktury zakupowej"
        >
          <div className="btnBase btnBaseSmall">
            <img alt="Pobierz XML" src={downloadXmlIcon} />
          </div>
        </Button>

        <Button
          kind="transparent-black"
          size="square-s"
          rel="noopener noreferrer"
          href={`${purchaseInvoice.tradingDocumentPdf?.pdf}`}
          target="_blank"
          as="a"
          title="Podgląd PDF z danymi faktury zakupowej"
        >
          <div className="btnBase btnBaseSmall">
            <img alt="Pobierz PDF" src={downloadPdfIcon} />
          </div>
        </Button>
        <MoreInfoButton close={close} invoice={purchaseInvoice} panelId={panelId} />
        <span className="divider line-divider" />
        <Button kind="transparent-black" onClick={close} size="square-s">
          <div className="btnBase btnBaseSmall">
            <img
              alt="Zamknij panel"
              src={hidePanelIcon}
              style={{ height: "16px", width: "16px" }}
            />
          </div>
        </Button>
      </div>
    </RightPanelHeader>
  );
};
