import { Button } from "components/common";
import qrCodeIcon from "assets/images/qrCode.svg";
import { useState } from "react";
import { CargoUnload } from "api/wms/models";
import styles from "../UnloadingsList.module.css";
import cx from "classnames";

interface Props {
  cargoUnload: CargoUnload;
  index: number;
}

export const CargoUnloadLabelDownload = ({ cargoUnload, index }: Props) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div onPointerOver={() => setShowTooltip(true)} onPointerLeave={() => setShowTooltip(false)}>
      <Button kind="transparent-black" size="square-s">
        <div className="btnBase btnBaseSmall btnBase16" data-for={String(cargoUnload.id)}>
          <img alt="" src={qrCodeIcon} />
        </div>
      </Button>
      {showTooltip && (
        <div
          className={cx(styles.hintbox, {
            [styles.firstItemHintboxPosition]: index === 0,
          })}
        >
          Pobierz etykietę
        </div>
      )}
    </div>
  );
};
