import { differenceInMinutes, parse } from "date-fns";

export const getFutureLoadingsTime = (
  estimatedTime: string,
): { status: "LATE" | "EARLY"; minutes: number } => {
  const currentTime = new Date();

  const estimatedDate = parse(estimatedTime, "HH:mm:ss", new Date());

  const differenceInMinutesValue = differenceInMinutes(estimatedDate, currentTime);

  console.log({ currentTime, estimatedTime, estimatedDate, differenceInMinutesValue });

  return {
    status: differenceInMinutesValue < 0 ? "LATE" : "EARLY",
    minutes: Math.abs(differenceInMinutesValue),
  };
};
