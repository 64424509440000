import { useTradingDocument } from "api/trading-documents/hooks";
import { RightPanelHeader } from "components/utils/drawer";
import { useQuery } from "hooks";
import { Button } from "components/common";
import downloadPdfIcon from "assets/images/download_pdf.svg";
import downloadXmlIcon from "assets/images/downloadXml.svg";
import hidePanelIcon from "assets/images/keyboardTab.svg";
import { ConfirmTradingDocument } from "pages/tradingDocuments/shared/components/rightPanel/ConfirmTradingDocument";
import { handleDownloadOrderXml } from "pages/tradingDocuments/shared/utils/handleDownloadOrderXml";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { ConfirmCheckbox } from "pages/tradingDocuments/shared/components/confirmCheckbox/ConfirmCheckbox";
import cx from "classnames";
import { MoreInfoButton } from "pages/tradingDocuments/shared/components/rightPanel/moreInfoButton/MoreInfoButton";

interface Props {
  close: () => void;
  isScrolledOver: boolean;
}

export const PanelHeader = ({ close, isScrolledOver }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const { data: purchaseInvoice } = useTradingDocument({ id: panelId });
  if (!purchaseInvoice) return null;

  return (
    <RightPanelHeader
      className={cx({
        slidingPanelHeaderShadow: isScrolledOver,
        panelHeaderDuringSlide: isScrolledOver,
      })}
    >
      {isScrolledOver ? (
        <div className="slidingPanelHeader">
          <div className="slidingPanelHeaderContent d-flex align-items-center gap-2">
            <ConfirmCheckbox tradingDocument={purchaseInvoice} />
            <div>Faktura VAT&nbsp;{purchaseInvoice.signature}</div>
          </div>
        </div>
      ) : (
        <ConfirmTradingDocument tradingDocument={purchaseInvoice} />
      )}
      <div className="d-flex align-items-center position-relative justify-content-end gap-1">
        <Button
          kind="transparent-black"
          onClick={() => {
            handleDownloadOrderXml(purchaseInvoice, downloadFeedbackToastr);
          }}
          size="square-s"
          title="Pobierz XML z danymi faktury zakupowej"
        >
          <div className="btnBase btnBaseSmall">
            <img alt="Pobierz XML" src={downloadXmlIcon} />
          </div>
        </Button>
        <Button
          kind="transparent-black"
          size="square-s"
          title="Podgląd PDF z danymi faktury zakupowej"
        >
          <a
            href={`${purchaseInvoice.tradingDocumentPdf?.pdf}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className="btnBase btnBaseSmall">
              <img alt="Pobierz PDF" src={downloadPdfIcon} />
            </div>
          </a>
        </Button>

        <MoreInfoButton close={close} invoice={purchaseInvoice} panelId={panelId} />

        <span className="divider line-divider" />
        <Button kind="transparent-black" onClick={close} size="square-s">
          <div className="btnBase btnBaseSmall">
            <img
              alt="Zamknij panel"
              src={hidePanelIcon}
              style={{ height: "16px", width: "16px" }}
            />
          </div>
        </Button>
      </div>
    </RightPanelHeader>
  );
};
