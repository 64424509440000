import { Button } from "components/common";
import checkIcon from "assets/images/checkSmall.svg";
import taskListIcon from "assets/images/ruleBlack.svg";
import assignIcon from "assets/images/assign.svg";
import binIcon from "assets/images/trashBin.svg";
import downloadPdfIcon from "assets/images/download_pdf.svg";
import downloadXmlIcon from "assets/images/downloadXml.svg";
import { MultipleCheckedItemsToolbar } from "components/common/multipleCheckedItemsToolbar/MultipleCheckedItemsToolbar";
import { useQuery, useSelector, useToggle } from "hooks";
import { HighlightedRow } from "api/other/models";
import { useState } from "react";
import { handleDownloadMultipleOrderXml } from "./handleDownloadMultipleOrderXml";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { InvoiceType, TradingDocumentType } from "api/trading-documents/models";
import editIcon from "assets/images/edit.svg";
import { DOMAIN } from "ENDPOINTS";
import { handleBulkEditInvoices } from "./handleBulkEditInvoices";
import { Modal, Select } from "components/utils";
import { AccountancyAccount } from "api/accountancy-accounts/models";
import { useMutation } from "hooks/useMutation";
import { patchBulkInvoices } from "api/invoices/calls";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { useQueryClient } from "react-query";
import { handleXmlDownloadForMultipleCorrections } from "./utils/handleXmlDownloadForMultipleCorrections";

interface Props {
  actualPage: string | number;
  close: () => void;
  quantity: number;
  highlightedRows?: HighlightedRow[];
  setHighlightedRows: React.Dispatch<React.SetStateAction<HighlightedRow[]>>;
  tradingDocument?: { type: TradingDocumentType; invoiceType: InvoiceType };
}
export const ActionToolbar = ({
  actualPage,
  close,
  quantity,
  highlightedRows,
  setHighlightedRows,
  tradingDocument,
}: Props) => {
  const { query } = useQuery();
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const me = useSelector(store => store.auth.user!);
  const modal = useToggle();
  const ledgerAccounts = useSelector(store => store.partials.ledgerAccounts);
  const [chosenAccountancyAccount, setChosenAccountancyAccount] = useState<AccountancyAccount>();
  const panelId = query.panelId;
  const queryClient = useQueryClient();

  const patchMutation = useMutation(patchBulkInvoices, {
    onSuccess: args => {
      queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.details(panelId));
    },
    onSettled: args => {
      modal.close();
    },
  });

  return (
    <MultipleCheckedItemsToolbar
      actionButtons={
        <>
          <Button kind="transparent-white" size="square-s">
            <div className="btnBase btnBaseSmall">
              <img alt="Więcej opcji" src={checkIcon} style={{ height: "16px", width: "16px" }} />
            </div>
            <div className="defaultHintbox">Zatwierdź faktury</div>
          </Button>
          <Button kind="transparent-white" size="square-s">
            <div className="btnBase btnBase16 btnBaseSmall">
              <img alt="Więcej opcji" src={taskListIcon} />
            </div>
            <div className="defaultHintbox">Zażądaj zmian</div>
          </Button>
          <Button kind="transparent-white" size="square-s">
            <div className="btnBase btnBase16 btnBaseSmall">
              <img alt="Więcej opcji" src={assignIcon} />
            </div>
            <div className="defaultHintbox">Przypisz</div>
          </Button>
          <Button kind="transparent-white" size="square-s">
            <div className="btnBase btnBase16 btnBaseSmall">
              <img alt="Więcej opcji" src={binIcon} />
            </div>
            <div className="defaultHintbox">Usuń</div>
          </Button>
          <Button kind="transparent-white" onClick={() => modal.open()} size="square-s">
            <div className="btnBase btnBase16 btnBaseSmall">
              <img alt="Więcej opcji" src={editIcon} />
            </div>
            <div className="defaultHintbox">Zmień konta księgowe dla zaznaczonych</div>
          </Button>
          {tradingDocument?.invoiceType === "SALES" && (
            <Button size="square-s" kind="transparent-black">
              <div>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`${DOMAIN}finances/trading-documents/sales-pdfs?tradingDocumentsIds=${encodeURIComponent(
                    highlightedRows ? highlightedRows.map(row => row.id).join(",") : "",
                  )}&user=${me.id}`}
                >
                  <div className="btnBase btnBaseSmall">
                    <img alt="Podgląd PDF" src={downloadPdfIcon} />
                  </div>
                </a>
              </div>
              <div className="defaultHintbox">Podgląd PDF dla zaznaczonych</div>
            </Button>
          )}

          {tradingDocument && tradingDocument.invoiceType !== "CORRECTION" && (
            <Button
              onClick={() => {
                if (highlightedRows) {
                  handleDownloadMultipleOrderXml({
                    tradingDocument: tradingDocument,
                    tradingDocumentsString: parseRows(highlightedRows).string,
                    downloadFeedbackToastr,
                  });
                }
              }}
              kind="transparent-white"
              size="square-s"
            >
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Więcej opcji"
                  src={downloadXmlIcon}
                  style={{ height: "22px", width: "22px" }}
                />
              </div>
              <div className="defaultHintbox">Pobierz XML dla zaznaczonych</div>
            </Button>
          )}

          {tradingDocument && tradingDocument.invoiceType === "CORRECTION" && (
            <Button
              onClick={() => {
                if (highlightedRows) {
                  handleXmlDownloadForMultipleCorrections(
                    tradingDocument,
                    parseRows(highlightedRows).string,
                    downloadFeedbackToastr,
                  );
                }
              }}
              kind="transparent-white"
              size="square-s"
            >
              <div className="btnBase btnBaseSmall">
                <img
                  alt="Pobierz XML"
                  src={downloadXmlIcon}
                  style={{ height: "22px", width: "22px" }}
                />
              </div>
              <div className="defaultHintbox">Pobierz XML dla zaznaczonych</div>
            </Button>
          )}

          <Modal isOpen={modal.isOpen} close={modal.close}>
            <div className="d-flex flex-column align-items-center justify-content-center pb-5">
              <h1 className="body-14 text-center mx-0">
                Jakie konto księgowe przypisać dla zaznaczonych faktur?
              </h1>
              <Select
                width="full"
                size="small"
                items={ledgerAccounts}
                onChange={value => {
                  if (value) setChosenAccountancyAccount(value);
                }}
              />
              <Button
                onClick={() => {
                  if (highlightedRows && chosenAccountancyAccount) {
                    handleBulkEditInvoices({
                      //@ts-ignore
                      tradingDocumentsArray: parseRows(highlightedRows).array,
                      fieldToUpdate: "ledgerAccount",
                      valueToUpdate: chosenAccountancyAccount.id,
                      mutation: patchMutation,
                    });
                  }
                }}
                className="mt-4 px-3"
                kind="secondary-grey"
                size="small"
              >
                Zatwierdź
              </Button>
            </div>
          </Modal>
        </>
      }
      close={close}
      quantity={quantity}
    />
  );
};

const parseRows = (rows: HighlightedRow[]) => {
  let stringArray = rows.map(tradingDocument => tradingDocument.id);
  return {
    string: stringArray.join(","),
    array: stringArray,
  };
};
