import { HighlightedRow } from "api/other/models";
import { UUID } from "api/types";
import { ModuleRow } from "components/utils/moduleList/components/ModuleRow";
import cx from "classnames";
import styles from "../ManufacturingStagesList.module.css";
import { manufacturingStagesListConfig } from "components/utils/moduleList/utils/moduleListConfig";
import { ManufacturingStage } from "api/manufacturingNew/models";

interface Props {
  highlightedRows: HighlightedRow[];
  index: number;
  isActive: (id: string) => boolean;
  isHighlighted: (id: string) => boolean;
  manufacturingStage: ManufacturingStage;
  selectMutlipleItems: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: UUID | number,
    index: number,
    togglePanel: () => void,
  ) => void;
  togglePanel: (id: string) => void;
  search: string;
}

export const ListItem = ({
  highlightedRows,
  index,
  isActive,
  isHighlighted,
  manufacturingStage,
  selectMutlipleItems,
  togglePanel,
  search,
}: Props) => {
  return (
    <>
      <ModuleRow
        className={cx("nowrap", styles.fixedSingleRouteRow, {
          active: isActive(String(manufacturingStage.id)),
          highlighted:
            isHighlighted(String(manufacturingStage.id)) ||
            highlightedRows.some(row => row.id === manufacturingStage.id),
        })}
        gridStyle={manufacturingStagesListConfig.grid}
        onMouseDown={event =>
          selectMutlipleItems(event, manufacturingStage.id, index, () =>
            togglePanel(String(manufacturingStage.id)),
          )
        }
      >
        <div>{manufacturingStage.name.length > 0 ? manufacturingStage.name : "--"}</div>
      </ModuleRow>
    </>
  );
};
