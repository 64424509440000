import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import {
  getManufacturingSchema,
  getManufacturingSchemas,
  getManufacturingStage,
  getManufacturingStages,
} from "./calls";
import { createApiQuery } from "hooks/createApiQuery";

export const useManufacturingSchemas = createPaginatedApiQuery(getManufacturingSchemas);

export const useManufacturingSchema = createApiQuery(getManufacturingSchema);

export const useManufacturingStages = createPaginatedApiQuery(getManufacturingStages);

export const useManufacturingStage = createApiQuery(getManufacturingStage);
