import { RightPanelHeader } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useRoute } from "hooks/apiPrimitives";
import cx from "classnames";
import { Button } from "components/common";
import requestQuoteIcon from "assets/images/requestQuoteDark.svg";
import downloadFileIcon from "assets/images/downloadFile.svg";
import historyIcon from "assets/images/history.svg";
import mapIcon from "assets/images/map_alt.svg";
import closeIcon from "assets/images/keyboardTab.svg";
import styles from "../RightPanel.module.css";
import { MarkRouteReadyBtn } from "./markRouteReady/MarkRouteReadyBtn";
import { MarkRouteReadyCheckbox } from "./markRouteReady/MarkRouteReadyCheckbox";
import { MoreOptionsPopUp } from "./moreOptionsPopUp/MoreOptionsPopUp";
import { Link } from "react-router-dom";

interface Props {
  close: () => void;
  isScrolledOver: boolean;
  setShowLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PanelHeader = ({ close, isScrolledOver, setShowLoader }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: route } = useRoute(panelId);

  if (!route) return null;

  return (
    <RightPanelHeader
      className={cx(styles.panelHeaderSizing, {
        slidingPanelHeaderShadow: isScrolledOver,
        panelHeaderDuringSlide: isScrolledOver,
      })}
    >
      {isScrolledOver ? (
        <div className="slidingPanelHeader">
          <div className="slidingPanelHeaderContent d-flex align-items-center gap-2">
            <MarkRouteReadyCheckbox route={route} setShowLoader={setShowLoader} />
            <div>{route.signature}</div>
          </div>
        </div>
      ) : (
        <MarkRouteReadyBtn route={route} setShowLoader={setShowLoader} />
      )}
      <div className="d-flex align-items-center justify-content-end gap-1 position-relative">
        <Button kind="create" style={{ height: "26px" }}>
          <div className="btnBase btnBaseSmall">Zatwierdź faktury</div>
          <div className={cx("defaultHintbox", styles.hintboxContent)}>
            Zatwierdź faktury zamówień
          </div>
        </Button>
        <Button kind="transparent-black" size="square-s">
          <div className="btnBase btnBase16 btnBaseSmall">
            <img alt="Podgląd" src={requestQuoteIcon} />
          </div>
          <div className="defaultHintbox">Podgląd PDF faktur</div>
        </Button>
        <Button kind="transparent-black" size="square-s">
          <div className="btnBase btnBase16 btnBaseSmall">
            <img alt="Pobierz" src={downloadFileIcon} />
          </div>
          <div className="defaultHintbox">Dokumenty do pobrania</div>
        </Button>
        <Button
          as={Link}
          kind="transparent-black"
          size="square-s"
          to={{
            pathname: `/logistics/routes/${panelId}/history`,
            state: { route },
          }}
        >
          <div className="btnBase btnBase16 btnBaseSmall">
            <img alt="Historia" src={historyIcon} />
          </div>
          <div className="defaultHintbox">Historia trasy</div>
        </Button>
        <span className="divider line-divider" />
        <Button kind="transparent-black" size="square-s">
          <div className="btnBase btnBase16 btnBaseSmall">
            <img alt="Edycja" src={mapIcon} />
          </div>
          <div className="defaultHintbox">Edytuj trasę</div>
        </Button>

        <MoreOptionsPopUp route={route} />

        <span className="divider line-divider" />
        <Button kind="transparent-black" onClick={close} size="square-s">
          <div className="btnBase btnBase16 btnBaseSmall">
            <img alt="Zamknij" src={closeIcon} />
          </div>
          <div className="defaultHintbox">Zamknij</div>
        </Button>
      </div>
    </RightPanelHeader>
  );
};
