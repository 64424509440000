import { CommonError, FormInput, FormSelect, Modal, Spinner } from "components/utils";
import { Formik, FormikHelpers } from "formik";
import { getAnyErrorKey, queryString, yup } from "utilities";
import cx from "classnames";
import { Button, useToastr } from "components/common";
import { useMutation } from "hooks/useMutation";
import { PostProductionPlan } from "api/new-production-plans/models";
import { postProductionPlan } from "api/new-production-plans/calls";
import { useHistory } from "react-router";
import { useManufacturingStages } from "api/manufacturingNew/hooks";

interface Props {
  close: () => void;
}

const initialValues: PostProductionPlan = {
  masterStage: "",
  name: "",
};

const validationSchema = yup.object().shape({
  masterStage: yup.string().required("Wskaż etap produkcji"),
});

export const CreateManufacturingPlanModal = ({ close }: Props) => {
  const { data: schemaStages, error, isLoading } = useManufacturingStages(
    queryString.stringify({ pageSize: 999 }),
  );
  const toastr = useToastr();
  const history = useHistory();

  const createProductionPlanMutation = useMutation(
    ({ values }: { values: PostProductionPlan; actions: FormikHelpers<PostProductionPlan> }) => {
      return postProductionPlan(values);
    },
    {
      onSuccess: payload => {
        history.replace(`/new-production-plans/details/${payload.id}`);
        close();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Utworzono nowy plan produkcji: ${payload.name} (${payload.signature})`,
        });
      },
      onError: (error, { actions }) => {
        actions.setErrors(error);
        actions.setSubmitting(false);
        toastr.open({
          type: "warning",
          title: "Wymagane działanie...",
          text: getAnyErrorKey(error),
        });
      },
    },
  );

  if (error) {
    return (
      <div className="h-100">
        <CommonError status={error._httpStatus_} />
      </div>
    );
  }

  return (
    <Modal
      close={close}
      isOpen={true}
      overrides={{ container: { className: "p-3" } }}
      size={{ all: { width: "480px" } }}
    >
      <>
        {isLoading && (
          <Spinner
            color="blue"
            size="small"
            text="trwa wczytywanie planów produkcyjnych"
            position="absolute"
          />
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            createProductionPlanMutation.mutate({ values, actions });
          }}
        >
          {({ handleSubmit, isSubmitting, isValid }) => (
            <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
              <div className="font-bold fs-18 mb-4">Utwórz plan produkcji</div>
              <div className="font-bold fs-16 mb-3 text-color-grey">Etap</div>
              <FormSelect
                items={schemaStages.map(schemaStage => {
                  return {
                    id: schemaStage.id,
                    name: schemaStage.name,
                  };
                })}
                itemToSelection={item => (item ? item.id : null)}
                label="Etap produkcji"
                name="masterStage"
                overwrites={{
                  wrapper: { className: "mb-3" },
                }}
                placeholder="Wybierz etap"
              />
              <div className="font-bold fs-16 mb-3 text-color-grey">Nazwa</div>
              <FormInput className="mb-4" label="Nazwa planu" name="name" />
              <div className="d-flex align-items-center mt-4 ">
                <Button
                  className="mr-3 fs-12 uppercase"
                  disabled={isSubmitting}
                  kind="primary"
                  type="submit"
                >
                  Utwórz
                </Button>
                <Button className="fs-12 uppercase" kind="secondary-stroke" onClick={close}>
                  Anuluj
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </>
    </Modal>
  );
};
