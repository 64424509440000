import { RightPanelHeader } from "components/utils/drawer";
import { Button } from "components/common";
import hidePanelIcon from "assets/images/keyboardTab.svg";
import moreOptionsIcon from "assets/images/threeDots.svg";
import cx from "classnames";
import { ManufacturingSchema } from "api/manufacturingNew/models";

interface Props {
  close: () => void;
  isScrolledOver: boolean;
  panelId: string;
  manufacturingSchema: ManufacturingSchema;
}

export const PanelHeader = ({ close, isScrolledOver, panelId, manufacturingSchema }: Props) => {
  return (
    <RightPanelHeader
      className={cx({
        slidingPanelHeaderShadow: isScrolledOver,
      })}
    >
      <div className="d-flex w-100 align-items-center">
        {isScrolledOver && (
          <div className="slidingPanelHeader">
            <div className="slidingPanelHeaderContent nowrap">{manufacturingSchema.name}</div>
          </div>
        )}
        <div className="d-flex align-items-center justify-content-end gap-1 flex-1">
          <span className="divider line-divider" />
          <Button kind="transparent-black" size="square-s">
            <div className="btnBase btnBaseSmall">
              <img
                alt="Więcej opcji"
                src={moreOptionsIcon}
                style={{ height: "16px", width: "16px" }}
              />
            </div>
          </Button>
          <span className="divider line-divider" />
          <Button kind="transparent-black" onClick={close} size="square-s">
            <div className="btnBase btnBaseSmall">
              <img
                alt="Zamknij panel"
                src={hidePanelIcon}
                style={{ height: "16px", width: "16px" }}
              />
            </div>
          </Button>
        </div>
      </div>
    </RightPanelHeader>
  );
};
