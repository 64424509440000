import { UUID } from "api/types";

type DayOfTheWeek =
  | "MONDAY"
  | "TUESDAY"
  | "WEDNESDAY"
  | "THURSDAY"
  | "FRIDAY"
  | "SATURDAY"
  | "SUNDAY";

export type EmployeeKind = "UPHOLSTERER" | "CUTTER" | "SEAMSTRESS";

type ManufacturingItemStatus = "PENDING" | "IN_PROGRESS" | "DONE";

type ManufacturingStageStatus = "NOT_STARTED" | "IN_PROGRESS" | "FINISHED";

type ManufacturingItemPriority = "A" | "B" | "C";

type ProductionPlanStatus = "NOT_STARTED" | "IN_PROGRESS" | "FINISHED";

export const productionPlanStatusDict: Record<ProductionPlanStatus, string> = {
  NOT_STARTED: "Nierozpoczęte",
  IN_PROGRESS: "W toku",
  FINISHED: "Zakończone",
};

type SchemaStageImplementedBy = "IN_HOUSE" | "CONTRACTOR";

export interface ProductionPlan {
  created: string;
  createdBy: ProductionPlanCreatedBy;
  days: WorkingDay[];
  employees: Employee[];
  id: UUID;
  isConfirmed: boolean;
  masterStage: ProductionPlanMasterStage | null;
  name: string;
  shelfFilters: ShelfFilters;
  signature: string;
  slotsSummary: SlotsSummary;
  sourcesSummary: SourcesSummary[];
  status: ProductionPlanStatus;
}

export interface SlotsSummary {
  total: number;
  used: number;
}

export interface SourcesSummary {
  sourceColor: string;
  sourceId: number;
  sourceSignature: string;
}

export interface ProductionPlanMasterStage {
  backgroundColor: string;
  id: UUID;
  name: string;
  textColor: string;
}

export interface ProductionPlanCreatedBy {
  avatar: string | null;
  firstName: string;
  id: number;
  isActive: boolean;
  isSuperuser: boolean;
  lastName: string;
  phone: string;
}

export interface PostProductionPlan {
  masterStage: string;
  name: string;
}

export interface WorkingDay {
  date: string;
  dayOfTheWeek: DayOfTheWeek;
  id: string;
  isHoliday: boolean;
}

export interface Employee {
  avatar: string | null;
  employeeWorkingDays: EmployeeWorkingDay[];
  firstName: string;
  id: number;
  isDeleted: boolean;
  kind: EmployeeKind;
  lastName: string;
  number: string;
}

export interface EmployeeWorkingDay {
  categorySummary: CategorySummary[];
  date: string;
  id: string;
  isAvailable: boolean;
  manufacturingWorkingUnits: ManufacturingWorkingUnit[];
  slots: {
    default: number;
    extra: number;
  };
}

interface CategorySummary {
  code: string | null;
  color: string | null;
  name: string | null;
  slots: number;
}

export interface ManufacturingWorkingUnit {
  id: string;
  index: Index;
  note: string;
  priority: ManufacturingItemPriority;
  signature: string;
  source: ManufacturingItemSource;
  status: ManufacturingItemStatus;
  visiblePreviousStages: ManufacturingStage[];
}

export interface ManufacturingStage {
  backgroundColor: string;
  code: string;
  id: string;
  name: string;
  status: ManufacturingStageStatus;
  textColor: string;
}

export interface ManufacturingItemSource {
  backgroundColor: string;
  id: number;
  signature: string;
}

export interface ShelfFilters {
  routes: {
    ids?: number[];
    departureDate?: string;
    isNull?: boolean;
  };
  orderGroups: {
    ids?: number[];
    departureDate?: string;
    isNull?: boolean;
  };
  orders: {
    createdAfter?: string;
    createdBefore?: string;
    deliveryMethod?: string;
    isCanceled?: boolean;
    isConfirmed?: boolean;
    shippingService?: number;
  };
  products: {
    categories?: number[];
  };
}

interface Index {
  attributesValues: string;
  category: {
    code: string | null;
    color: string | null;
    name: string | null;
  };
  name: string;
}

export interface ProductionPlanListItem {
  created: string;
  createdBy: number;
  employees: EmployeeOnListItem[];
  id: string;
  isConfirmed: boolean;
  masterStage: string;
  name: string;
  rangeOfDates: {
    firstDate: string;
    lastDate: string;
  };
  signature: string;
  status: ProductionPlanStatus;
  workingDays: WorkingDay[];
}

export interface ManufacturingSchema {
  createdAt: string;
  id: string;
  lastUpdatedAt: string;
  name: string;
}

export interface SchemaStage {
  id: string;
  implementedBy: SchemaStageImplementedBy;
  name: string;
  parent: string | null;
  position: number;
  schemas: string[];
}

interface EmployeeOnListItem {
  avatar: string | null;
  dateOfDismissal: string | null;
  dateOfEmployment: string | null;
  firstName: string;
  id: number;
  isActive: boolean;
  kind: EmployeeKind;
  lastName: string;
  number: string;
  phone: string;
}

export interface ShelfItem {
  filterValue: number | null;
  header: string;
  origin: string;
}

export interface ShelfManufacturingItem {
  createdAt: string | null;
  createdBy: {
    avatar: string | null;
    firstName: string;
    id: number;
    isActive: boolean;
    isSuperuser: boolean;
    lastName: string;
    phone: string;
  } | null;
  finishedAt: string | null;
  id: string;
  identity: string;
  index: Index;
  indexId: string;
  itemId: string;
  lastUpdatedAt: string | null;
  modifiedBy: {
    avatar: string | null;
    firstName: string;
    id: number;
    isActive: boolean;
    isSuperuser: boolean;
    lastName: string;
    phone: string;
  } | null;
  note: string;
  priority: ManufacturingItemPriority;
  signature: string;
  source: ManufacturingItemSource;
  status: ManufacturingItemStatus;
  visiblePreviousStages: ManufacturingStage[];
}

export interface AddOrRemoveEmployeesFromProductionPlan {
  stageManufacturingPlanId: string;
  employees: number[];
}

export interface AddOrRemoveWorkingDaysInProductionPlan {
  stageManufacturingPlanId: string;
  workingDays: string[];
}
