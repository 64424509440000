import { CargoUnloadTab } from "api/wms/models";
import { queryString } from "utilities";

type GetSearchProps = {
  query: {
    [x: string]: string;
  };
  tab: CargoUnloadTab;
};

export const getSearch = ({ query, tab }: GetSearchProps) => {
  const tabsQuery = {
    status:
      tab === "not-started"
        ? "NOT_STARTED"
        : tab === "on-going"
        ? "STARTED"
        : tab === "finished"
        ? "FINISHED"
        : "",
  };

  return queryString.stringify({
    ...query,
    ...tabsQuery,
  });
};
